<template>
  <div class="bg-gray py-16 lg:py-36">
    <div class="container flex flex-col items-center text-neutral-900">
      <h2 class="mb-3 text-4xl lg:text-6xl">{{ data.title }}</h2>
      <p class="mb-10 text-base lg:mb-16">{{ data.subtitle }}</p>
      <form v-if="!formSubmittedSuccessfully" @submit.prevent="submit">
        <div class="mb-6 inline-flex items-center">
          <input
            v-model="email.value"
            class="max-h-20 w-full border border-neutral-400 bg-white px-8 py-7 text-base outline-none transition-colors placeholder:text-neutral-600 hover:border-black lg:min-w-[336px]"
            :class="{ 'border-red text-red hover:border-red': email.error }"
            :placeholder="$t('newsletter.placeholder')"
            @input="checkEmailValidity"
          />
          <button
            :title="$t('newsletter.submit')"
            class="h-20 min-w-[80px] bg-black transition-colors duration-300 hover:bg-opacity-70 disabled:bg-neutral-400"
            :icon="{ name: 'arrow-right', color: 'white', size: 'lg' }"
            :disabled="email.error"
          >
            <Icon name="arrow-right" color="white" size="lg" />
          </button>
        </div>

        <p v-if="email.error" class="-mt-4 mb-6 text-xs text-red">
          {{ $t('formContact.validEmail') }}
        </p>

        <div class="flex items-center gap-3">
          <input
            id="newsletter-terms"
            v-model="termsAccepted"
            type="checkbox"
            name="newsletter-terms"
            class="h-4 w-4 cursor-pointer border-neutral-400 accent-black ring-inset"
            :class="checkboxInvalid ? 'ring-1 ring-red' : 'ring-0'"
            @input="checkboxInvalid = false"
          />
          <label class="text-xs" for="newsletter-terms">
            {{ $t('newsletter.checkboxLabel') }}
            <NuxtLink
              :to="localePathByType('page', data.routeLink?.slug)"
              class="font-bold hover:underline"
              >{{ $t('newsletter.terms') }}</NuxtLink
            >
          </label>
        </div>
      </form>
      <Transition name="fade">
        <div v-if="formSubmittedSuccessfully">
          <div
            class="mx-auto mb-2 flex h-10 w-10 items-center justify-center rounded-full bg-green-light"
          >
            <Icon name="check" size="xs" />
          </div>
          <p class="text-base font-medium text-neutral-900">
            {{ $t('newsletter.submittedMessage') }}
          </p>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Newsletter {
  title: string
  subtitle: string
  routeLink: {
    id: string
    slug: string
  }
}

defineProps({
  data: {
    type: Object as PropType<Newsletter>,
    default: () => {}
  }
})

const { localePathByType } = useLocalePathByType()

const formSubmittedSuccessfully = ref(false)
const termsAccepted = ref(false)
const checkboxInvalid = ref(false)

const email = reactive({
  value: '',
  error: false
})

function checkEmailValidity() {
  email.error = !validateEmail(email.value)
}

async function sendMail() {
  try {
    const response = await fetch('/.netlify/functions/subscribe', {
      method: 'POST',
      body: JSON.stringify({ email: email.value })
    })

    // console.log(response, 'response client side')

    if (response.ok) {
      formSubmittedSuccessfully.value = true
    }
  } catch (error) {
    console.error(error)
  }
}

async function submit() {
  checkEmailValidity()

  if (!termsAccepted.value) {
    checkboxInvalid.value = true
  } else if (!email.error) {
    await sendMail()
  }
}
</script>
